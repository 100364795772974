<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="物业名称">
                <el-input size="small" v-model="form.property_name"></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
                <el-date-picker @change="getTime" v-model="form.create_time" size="small" value-format="timestamp" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="提现单号">
                <el-input size="small" v-model="form.withdraw_no"></el-input>
            </el-form-item>
            <el-form-item label="审核状态">
                 <el-select size="small" v-model="form.status" placeholder="请选择">
                    <el-option label="待审核" value="0"></el-option>
                    <el-option label="已审核" value="1"></el-option>
                    <el-option label="已打款" value="2"></el-option>
                    <el-option label="已驳回" value="-1"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="search" size="small">搜索</el-button>
                <el-button type="primary" @click="exportData" size="small">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="create_time | getDateformat" label="申请时间" align="center"> 
                <template v-slot="{ row }">
                    <span>{{ getDateformat(row.create_time) }}</span>
                </template>   
            </el-table-column>
            <el-table-column prop="withdraw_no" label="提现单号" align="center"> </el-table-column>
            <el-table-column prop="property_name" label="物业名称" align="center"> </el-table-column>
            <el-table-column prop="order_total_price" label="订单金额" align="center"></el-table-column>
            <el-table-column prop="order_num" label="结算订单数" align="center"></el-table-column>
            <el-table-column prop="property_settlement_money" label="物业结算金额" align="center"></el-table-column>
            <el-table-column prop="dk_money" label="打款金额" align="center"></el-table-column>
            <el-table-column label="审核状态" align="center">
                <template v-slot="{ row }">
                    <span>{{ row.status==0?'待审核':
                             row.status==1?"已审核":
                             row.status==2?"已打款":
                             row.status==-1?"已驳回":
                             row.status }}</span>
                </template>  
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="$router.push({ path: '/repair/finance/accountsDetail', query: { id: scope.row.id } })">查看详情</el-button>
                    <el-button v-if=" scope.row.status == 1" type="text" @click="onRemit(scope.row)">打款</el-button>
                    <el-button v-if=" scope.row.status == 0" type="text" @click="checkWithdraw(scope.row)">审核</el-button>
                    <el-button v-if=" scope.row.status == 2" type="text" @click="remitLog(scope.row)">打款凭证</el-button>
                </template>
            </el-table-column>
        </el-table>
<!-- 小卡片 -->
<el-dialog title="打款" :visible.sync="remitdialog" width="50%">
<ul class="cardUl">
  <li>
    <div class="cardLi">
      <div class="content cardLiTab">
        <el-form ref="remit" :model="remit" label-width="130px" label-position="left">
            <el-form-item label="打款类型：">
                银行卡
            </el-form-item>
            <el-form-item label="开户行：">
                {{card.bank_info}}
            </el-form-item>
            <el-form-item label="卡号：">
                     {{card.bank_card}}
            </el-form-item>
            <el-form-item label="持卡人：">
                 {{card.bank_name}}
            </el-form-item>
            
            <el-form-item label="打款金额：">
              <el-input type="text" v-model="remit.dk_money"></el-input>
            </el-form-item>
       
            <el-form-item label="打款凭证：" class="item" >
                <ReadyUploadSource @getSource="getSource" @changeOrder="list => (remit.voucher = list)"
                @removeThis="index => remit.voucher.splice(index, 1)" :isMany="true"
                :manyPath="remit.voucher" :isManyMax="10" :maxSize="1024 * 1024"></ReadyUploadSource>
                 <p class="tips">建议图片尺寸800*800px，大小不超过1M，最多可以上传10张图片</p>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea" v-model="remit.remark"></el-input>
            </el-form-item>
            <el-form-item class="formBtn">
                <el-button @click="remitdialog = false">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
</div>
</div>
</li>
</ul>
</el-dialog>

<!-- 小卡片 -->
<el-dialog title="处理" :visible.sync="checkdialog" width="40%">
<ul class="cardUl">
  <li>
    <div class="cardLi">
      <div class="content cardLiTab">
        <el-form ref="checkStatus" :model="checkStatus" label-width="130px" label-position="left">
        <el-form-item label="请选择审核结果">
                  <el-radio-group v-model="checkStatus.status">
                    <el-radio label="1">同意</el-radio>
                    <el-radio label="-1">驳回</el-radio>
                  </el-radio-group>
        </el-form-item>

        <el-form-item v-if="checkStatus.status== -1" label="驳回原因:">
          <el-input type="textarea" v-model="checkStatus.refuse_reason"></el-input>
        </el-form-item>
        <el-form-item class="formBtn">
          <el-button @click="checkdialog = false">取消</el-button>
          <el-button type="primary" @click="oncheckSubmit">确定</el-button>
        </el-form-item>
      </el-form>
  </div>
</div>
</li>
</ul>
</el-dialog>

<el-dialog title="打款凭证" :visible.sync="remitLogDialog" width="40%">
<ul class="cardUl">
  <li>
    <div class="cardLi">
      <div class="content cardLiTab">
        <el-form ref="checkStatus" :model="checkStatus" label-width="130px" label-position="left">
             <el-image style="width: 100px; height: 100px"  :src="i_item" v-for="(i_item, i_index) in currentInfo.voucher_d" :key="i_index" :prediv-src-list="currentInfo.voucher_d"></el-image>
      </el-form>
  </div>
</div>
</li>
</ul>
</el-dialog>

        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateServiceData"></Paging>
    </el-main>
</template>

<script>
import config from '@/util/config';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
        ReadyUploadSource,
    },
    data () {
        return {
            showOptions: [
                {
                    value: 0,
                    label: '全部',
                },
                {
                    value: 1,
                    label: '否',
                },
                {
                    value: 2,
                    label: '是',
                },
            ],
            is_show: 0,
            name: '',
            remitLogDialog:false,
            remitdialog:false,
            checkdialog:false,
            list: [],
            card:[],
            dialogVisible: false,
            currentInfo:[],
            remit:{
                voucher:[],
                dk_money:''
            },
            form: {
                page: 1,
                rows: 10
            },
            checkStatus:{

            },
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        getList () {
            let data = this.form
            if (this.form.create_time && this.form.create_time.length) {
                data.start_time = this.form.create_time[0] / 1000;
                data.end_time = this.form.create_time[1] / 1000;
            }
            this.$axios
                .post(this.$api.repair.finance.propertyWithdrawList, data)
                .then(res => {
                    if (res.code == 0) {
                      let list = res.result.list;
                      this.total_number = res.result.total;
                      this.list = list;
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        // 获取组件中选中的图片
        getSource (list) {
            list.map(item => {
                this.remit.voucher.push(item.path);
            });
        },
        checkWithdraw(item)
        {
            this.checkdialog = true;
            this.currentInfo = item
        },
        remitLog(item)
        {
            this.remitLogDialog = true;
            this.currentInfo = item
        },
        oncheckSubmit(){
            let data = this.checkStatus
            data.id = this.currentInfo.id
             this.$axios
                .post(this.$api.repair.finance.checkWithdrawStatus, data)
                .then(res => {
                    if (res.code == 0) {
                        this.getList()
                        this.checkdialog = false
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        onSubmit(){
            let data = this.remit
                data.id = this.currentInfo.id
             this.$axios
                .post(this.$api.repair.finance.remit, data)
                .then(res => {
                    if (res.code == 0) {
                        this.getList()
                        this.remitdialog = false
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        onRemit(item){
            this.remitdialog = true
            this.currentInfo = item
            this.$axios
                .post(this.$api.repair.finance.getBankCard, {property_id:item.property_id})
                .then(res => {
                    if (res.code == 0) {
                      let card = res.result;
                      this.card = card;
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        exportData(){
            this.form.is_export = 1;
            let data = this.form
            if (this.form.create_time && this.form.create_time.length) {
                data.start_time = this.form.create_time[0] / 1000;
                data.end_time = this.form.create_time[1] / 1000;
            }
            delete(data.create_time)
            this.$axios
                .post(this.$api.repair.finance.propertyWithdrawList, data)
                .then(res => {
                    if (res.code == 0) {
                        let path = res.result;
                        let a = document.createElement('a');
                        a.href = path;
                        a.id = 'download';
                        document.body.appendChild(a);
                        a.click();
                        let aDom = document.getElementById('download');
                        document.body.removeChild(aDom);
                    } else {
                      this.$message.error(res.msg);
                    }
                });
        },
        exportGoodsClass () {
            this.$axios.post(this.$api.goods.exportGoodsClass).then(res => {
                if (res.code == 0) {
                    let path = config.baseurl + '/' + res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        editChange (row) {
            this.$axios
                .post(industryName + this.$api.goods.editType, {
                    id: row.id,
                    parent_id: row.parent_id,
                    name: row.name,
                    picture: row.picture,
                    sort: row.sort,
                    is_show: row.is_show,
                    type: row.type,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$store.dispatch('goods/getTypeList', {
                            industryName,
                            is_show: this.is_show,
                            name: this.name,
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        showValChange (val) {
            this.is_show = Number(val);
        },
        //搜索分类
        search () {
            this.getList()
        },
        cancelSearch () {
            this.form = {}
            this.getList()
        },
        //编辑分类
        edit (row) {
            this.$router.push({
                path: '/gateway/goods/editGoodsType',
                query: {
                    info: JSON.stringify(row),
                },
            });
        },
        //删除分类
        remove (row) {
            this.$confirm('确认删除此商品分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(industryName + this.$api.goods.delType, {
                        id: row.id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.getList();
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}

.cardUl {
  width: 100%;
  background-color: #fff;

  justify-content: space-between;
  flex-wrap: wrap;

  li {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: gray;
    }
  }

  .cardLi {
    width: 100%;
    position: relative;

    .cardLiTil {
      background-color: #f0eeee;
      color: gray;
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cardLiTab {
      padding: 20px;
    }

    .cardTabPage {
      display: flex;
      justify-content: center;

      position: absolute;
      bottom: 20px;
    }
  }
}
</style>
